<template>
	<v-main>
		<v-form @submit.prevent="performLogin">
			<v-card
				elevation="0"
				light
				tag="section"
				max-width="450px"
				rounded
				class="mx-auto my-12"
			>
				<v-card-text>
					<v-text-field
						outlined
						label="Username"
						type="text"
						v-model="username"
						required
					></v-text-field>
					<v-text-field
						outlined
						hide-details
						required
						label="Password"
						v-model="password"
						placeholder="············"
						:type="isPasswordVisible ? 'text' : 'password'"
						:append-inner-icon="
							isPasswordVisible
								? 'mdi-map-marker'
								: 'mdi-map-marker'
						"
						@click:append-inner="
							isPasswordVisible = !isPasswordVisible
						"
					></v-text-field>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions
					:class="{ 'pa-3': $vuetify.breakpoint.smAndUp }"
				>
					<v-spacer></v-spacer>

					<v-btn
						color="info"
						block
						flat
						:large="$vuetify.breakpoint.smAndUp"
						:loading="loading"
						type="submit"
						class="mt-2"
						>Login</v-btn
					>
				</v-card-actions>
			</v-card></v-form
		>

		<v-snackbar v-model="snackbar" :color="snackbarColor" multi-line>
			{{ snackbarText }}
			<v-btn text @click="snackbar = false">Close</v-btn>
		</v-snackbar>
	</v-main>
</template>
<script>
export default {
	data: () => ({
		snackbar: false,
		snackbarText: "",
		snackbarColor: "success",
		platformName: "MCSummary",
		username: "admin",
		password: "",
		isPasswordVisible: false,
		loading: false,
	}),
	methods: {
		showSnackbar(message, color) {
			this.snackbarText = message;
			this.snackbarColor = color || "success";
			this.snackbar = true;
		},
		submit() {
			this.loading = true;
		},
		performLogin() {
			this.loading = true;
			if (this.username == "admin" && this.password == "j3helpbooster") {
				localStorage.isAuth = true;
				this.$router.push({ name: "welcome" });
			} else {
				this.showSnackbar(
					"Password Incorrect, please try again.",
					"error",
				);
				this.loading = false;
				this.password = "";
			}
		},
	},
};
</script>
<style lang="scss">
.vcard {
	.v-card--variant-elevated {
		box-shadow: 0 2px 9px -2px var(--v-shadow-key-umbra-opacity),
			0 4px 9px 1px var(--v-shadow-key-penumbra-opacity),
			0 2px 6px 4px var(--v-shadow-key-ambient-opacity);
	}
}
</style>
